<template>
  <div class="container">
    <div id="header" class="w-full fixed px-8">
      <div class="links flex items-center justify-center m-auto text-3xl">
        <div class="hamburger absolute flex sm:hidden flex-col" v-on:click="toggleMenu">
          <div class="line mb-2"></div>
          <div class="line mb-2"></div>
          <div class="line"></div>
        </div>
        <a class="hidden sm:block" data-anchor="where" v-on:click="scrollTo">Where is Founder?</a>
        <img class="logo mx-16" alt="#LostAndFounder" src="../assets/images/logo.png" data-anchor="home" v-on:click="scrollTo">
        <a class="hidden sm:block" data-anchor="log" v-on:click="scrollTo">Founder's Travel Log</a>
      </div>
    </div>
    <div id="mobile-menu-overlay"></div>
    <div id="mobile-menu" class="flex sm:hidden flex-col items-center text-3xl py-20 px-8">
      <img class="mobile-logo" alt="#LostAndFounder" src="../assets/images/logo.png" data-anchor="home" v-on:click="scrollTo">
      <div id="close-menu" v-on:click="toggleMenu"></div>
      <a class="mt-12 mb-8" data-anchor="where" v-on:click="scrollTo">Where is Founder?</a>
      <a class="mb-8" data-anchor="log" v-on:click="scrollTo">Founder's Travel Log</a>
    </div>
    <div id="home"></div>
    <div id="hero" class="w-full flex items-center justify-center pt-16">
      <img src="../assets/images/hero-desktop.png" />
    </div>
    <div class="body pt-16 px-8 text-left">
      <div id="where" class="mb-16">
        <h1 class="mb-4">Where is Founder?</h1>
        <p class="mb-8">
          Founder is out traveling the world to find himself as he explores the globe. 
          Training his mind, body and soul, he prepares himself for hopes of one day reaching Smashy Bet heavyweight championship stardom. 
          <br />
          <br />
          <a href="https://docs.google.com/spreadsheets/d/1qIOo4QE7rLyjsC7eewIuFa07Lk8r8iUFAknRcpvPzpc/edit#gid=781168208" target="_blank">
            Founder's Smashy Bet Stat Sheet &nbsp;<i data-anchor="map" class="fas fa-external-link-alt"></i>
          </a>
          <br /><br />
          Use this handy map to see Founder's current whereabouts, as well as previous and future destinations!
          <br />
          <span class="emphazied">Check back here for periodic updates on Founder's location around the world!</span>
        </p>
        <div id="map"></div>
      </div>
      <div id="log">
        <h1 class="mb-4">Founder's Travel Log</h1>
        <h2 class="text-xl mb-4"><span class="emphazied">Current Status:</span> Back home with Dan!</h2>
        <h2 class="text-xl mb-4"><span class="emphazied">Total Distance Traveled:</span> 15,695 miles (25,259 km)</h2>
        <div class="log-entry flex justify-between pb-4" v-for="data in sortedData" :key="data.id">
          <div class="w-full">
            <p class="pt-4 pb-1" v-if="data.user != null">
              <span class="emphazied">Host:</span> {{data.user}}
            </p>
            <p class="py-1" v-if="data.location != null">
              <span class="emphazied">Location:</span> &nbsp;
              <a href="javascript:;" data-anchor="map" v-on:click="goToMap($event, data)">
                {{data.location}} &nbsp;<i data-anchor="map" class="fas fa-external-link-alt"></i>
              </a>
            </p>
            <p class="py-1" v-if="data.arrival != null">
              <span class="emphazied">Arrival:</span> {{data.arrival}}
            </p>
            <p class="py-1" v-if="data.departure != null">
              <span class="emphazied">Departure:</span> {{data.departure}}
            </p>
            <div class="w-full" v-if="data.activities != null">
              <p class="py-1 mb-2"><span class="emphazied">Activities: </span><span v-html="data.activities"></span></p>
              <!-- photo gallery -->
              <div class="gallery-img flex flex-wrap sm:flex-nowrap justify-between mb-2 w-full" v-if="data.images > 0">
                <div class="image w-full sm:w-1/3 h-96 bg-center bg-cover cursor-pointer mt-2 sm:mx-2"
                  v-for="img in getGalleryData(data.id, 'preview')" :key="img.index"
                  v-on:click="openLightbox(data.id, img.index)"
                  :style="'background-image:url('+img.src+')'"></div>
              </div>
              <div class="flex justify-end mt-4 sm:mr-2" v-if="data.images > 0">
                <a href="javascript:;" class="font-bold" v-on:click="openLightbox(data.id, 0)">
                  View full gallery &nbsp;<i class="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <!-- photo lightbox -->
              <vue-easy-lightbox v-if="data.images > 0"
                moveDisabled
                :visible="getGalleryData(data.id, 'visible')"
                :imgs="getGalleryData(data.id, 'imgs')"
                :index="getGalleryData(data.id, 'index')"
                @hide="handleHide(data.id)"
              ></vue-easy-lightbox>
            </div>
          </div>
        </div>
      </div>
      <div id="footer" class="text-center py-8 mt-8 text-sm sm:text-base">
        &copy; {{year}} Smashy Bet
      </div>
    </div>
  </div>
</template>

<script>
import { goToMarker } from './../helpers';

export default {
  name: 'Home',
  props: {
    subtitle: String
  },
  data() {
    return {
      year: new Date().getFullYear(),
      founderData: [],
      gallery: []
    }
  },
  created() {
    this.founderData = this.$founder_data.sort((a,b) => a.id - b.id);
    // set up image gallery for each host
    let galleryData = this.founderData.filter(d => d.id > 0);
    galleryData.forEach(data => {
      let obj = {
        id: data.id,
        imgs: [],
        preview: [],
        visible: false,
        index: 0
      }
      // push all images to array
      let offset = data.offset_images || 0;
      for (let i = 0; i < data.images; i++) {
        obj.imgs.push(`/images/${data.folder || data.user}/${i + 1 + offset}.jpg`);
      }
      obj.preview = this.getPreviewImgs(obj, data.images, data.offset_images);
      this.gallery.push(obj);
    });
  },
  mounted() {
    // scroll event for header
    document.addEventListener('scroll', () => {
      let scrollY = window.scrollY;
      let headerClass = document.getElementById('header').classList;
      let bodyClass = document.querySelector('.body').classList;
      let heroClass = document.getElementById('hero').classList;
      if (scrollY > 0 && !headerClass.contains('shrink') && !bodyClass.contains('shrink')) {
        document.getElementById('header').classList = `${headerClass} shrink`;
        document.querySelector('.body').classList = `${bodyClass} shrink`;
        document.getElementById('hero').classList = `${heroClass} shrink`;
      } else if (scrollY === 0 && headerClass.contains('shrink') && bodyClass.contains('shrink')) {
        document.getElementById('header').classList = headerClass.value.split(' shrink')[0];
        document.querySelector('.body').classList = bodyClass.value.split(' shrink')[0];
        document.getElementById('hero').classList = heroClass.value.split(' shrink')[0];
      }
    });
  },
  computed: {
    sortedData() {
      let data = this.founderData;
      return data.sort((a,b) => a.id - b.id);
    }
  },
  methods: {
    scrollTo: (e) => {
      let anchor = e.target.attributes['data-anchor'].value;
      let offset = document.getElementById(anchor).offsetTop;
      let bodyClass = document.querySelector('.body').classList;
      if (anchor === 'home') {
        window.scroll({ top: 0, behavior: 'smooth' });
      } else {
        window.scroll({ top: offset - (bodyClass.contains('shrink') ? 125 : 225), behavior: 'smooth' });
      }
    },
    goToMap(e, data) {
      this.scrollTo(e);
      goToMarker(data);
    },
    toggleMenu: () => {
      let menu = document.getElementById('mobile-menu');
      let overlay = document.getElementById('mobile-menu-overlay');
      if (menu.classList.contains('open')) {
        menu.classList = menu.classList.value.split(' open')[0];
        overlay.classList = overlay.classList.value.split(' open')[0];
      } else {
        menu.classList = `${menu.classList} open`;
        overlay.classList = `${overlay.classList} open`;
      }
    },
    getGalleryData(id, prop) {
      let obj = this.gallery.find(g => g.id == id);
      return obj[prop];
    },
    getPreviewImgs(obj, count, offset = 0) {
      // pick a random 3 images to show as a preview if there are more than 3
      if (count <= 3) {
        return obj.imgs.map((img, index) => ({index: index, src: img}));
      } else {
        let imgs = [];
        let min = offset > 0 ? count : 0;
        let max = count + offset;
        let rand = Math.ceil(Math.random() * (max - min + 1) + min);
        for (let i = 0; i < 3; i++) {
          if (rand <= max) {
            let num = rand == 0 ? rand + 1 : rand - 1;
            imgs.push({
              index: num - offset,
              src: obj.imgs[num - offset]
            });
            rand++;
          } else {
            rand = 0;
            imgs.push({
              index: rand,
              src: obj.imgs[rand]
            });
          }
        }
        return imgs;
      }
    },
    openLightbox(id, index) {
      let obj = this.gallery.find(g => g.id == id);
      obj.visible = true;
      obj.index = index;
    },
    handleHide(id) {
      let obj = this.gallery.find(g => g.id == id);
      obj.visible = false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  font-family: 'Bebas Neue', 'Arial', 'sans-serif';
  font-size: 2rem;
  line-height: 2.25rem;
  color: #134ad3;
}
ul {
  list-style-type: square;
}
a {
  color: #134ad3;
  cursor: pointer;
}
.container {
  max-width: 1200px;
  margin: auto;
  line-height: 1.5rem;

}
#header {
  background: #3561ce;
  font-family: 'Bebas Neue', 'Arial', 'sans-serif';
  top: 0;
  left: 0;
  transition: all 0.3s ease;
  z-index: 100;
}
  #header.shrink .logo {
    max-width: 100px;
  }
  #header .links {
    position: relative;
    max-width: 1200px;
  }
  #header .links a,
  #mobile-menu a {
    color: #fff;
    transition: all 0.3s ease;
  }
  #header .links a:hover,
  #mobile-menu a:hover {
    color: #000;
  }
#hero {
  position: absolute;
  left: 0;
  top: 200px;
  height: 300px;
  transition: all 0.5s ease;
}
  #hero.shrink {
    top: 100px;
  }
  #hero .overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  #hero h1 {
    color: #fff;
    font-size: 4rem;
    line-height: 4rem;
    position: relative;
    z-index: 2;
  }
.hamburger {
  height: 30px;
  width: 30px;
  left: 0;
}
  .hamburger .line {
    width: 100%;
    height: 2px;
    background: #fff;
  }
#mobile-menu {
  position: fixed;
  height: 100vh;
  width: 300px;
  left: -300px;
  top: 0;
  background: #3561ce;
  font-family: 'Bebas Neue', 'Arial', 'sans-serif';
  transition: all 0.3s ease;
  z-index: 300;
}
  #mobile-menu.open {
    left: 0;
  }
#mobile-menu-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(100,100,100,0.8);
  transition: all 0.3s ease;
  opacity: 0;
  z-index: -1;
}
  #mobile-menu-overlay.open {
    opacity: 1;
    z-index: 200;
  }
#close-menu {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 30px;
  height: 30px;
}
  #close-menu:before,
  #close-menu:after {
    position: absolute;
    content: "";
    height: 2px;
    width: 30px;
    background: #fff;
  }
  #close-menu:before {
    transform: rotate(-45deg);
  }
  #close-menu:after {
    transform: rotate(45deg);
  }
.logo {
  max-width: 200px;
  transition: all 0.5s ease;
  cursor: pointer;
}
.mobile-logo {
  position: absolute;
  left: 4px;
  top: 0;
  width: 80px;
  height: 80px;
}
.subtitle {
  position: relative;
}
  .subtitle h1 {
    font-size: 3rem;
    line-height: 1;
  }
  .subtitle:after {
    content: "";
    position: absolute;
    width: 300px;
    height: 2px;
    background: #000;
    top: 143px;
    left: calc(50% - 150px);
  } 
.body {
  font-family: 'Roboto', 'Arial', 'sans-serif';
  font-weight: 400;
  margin-top: 500px;
  transition: all 0.5s ease;
}
  .body.shrink {
    margin-top: 400px;
  }
.button-container {
  position: relative;
  width: 100px;
}
  .button-container:hover .button-bg {
    width: 100px;
  }
  .button-container.cta:hover .button-bg {
    width: 225px;
  }
.log-entry {
  border-top: 2px solid #000;
}
button {
  position: relative;
  width: 100%;
  padding: 0.75em;
  border: 2px solid #000;
  transition: all 0.3s ease;
  font-weight: 500;
  z-index: 2;
}
  button:hover {
    color: #fff;
    border: 2px solid #134ad3;
  }
.cta button {
  width: 225px;
}
.button-bg {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: #134ad3;
  transition: all 0.3s ease-out;
  z-index: 1;
}
.emphazied {
  color: #134ad3;
  font-weight: 700;
}
#footer {
  background: #3561ce;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  width: 100%;
  left: 0;
}
@media (max-width: 640px) {
  .subtitle:after {
    top: 130px;
  } 
}
</style>

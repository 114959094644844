import Vue from 'vue'
import VueEasyLightbox from 'vue-easy-lightbox'
import App from './App.vue'
import 'tailwindcss/tailwind.css'

Vue.config.productionTip = false
Vue.prototype.$founder_data = window.founder_data
Vue.use(VueEasyLightbox)

new Vue({
  render: h => h(App),
}).$mount('#app')
